@import "_settings/globalSetting";
@import "./product/originalAndLowestPrice";

.cc-recommendations, .cc-recommendations-lh {
    border-top: 1px solid $grey2;
    padding: rem-calc(20) 0 rem-calc(40);
    @include media-breakpoint-up (sm) {
        padding: rem-calc(30) 0;
    }
    @include media-breakpoint-up (lg) {
        padding: rem-calc(40) 0 rem-calc(60);
        margin: auto;
        max-width: 1440px;
    }
    @include media-breakpoint-down (xs) {
        margin-left: rem-calc(-15);
        margin-right: rem-calc(-15);
        overflow: hidden;
    }
    .carousel-title {
        margin-bottom: rem-calc(21);
        font-size: rem-calc(19);
        line-height: rem-calc(24);
        font-weight: $primary-font-medium;
        text-align: center;
        @include media-breakpoint-up (sm) {
            margin-bottom: rem-calc(30);
        }
        @include media-breakpoint-up (lg) {
            padding: 0 rem-calc(15);
        }
    }
    .slick-list {
        margin: 0 rem-calc(-6.5);
        @include media-breakpoint-up (sm) {
            margin: 0 rem-calc(30);
        }
        @include media-breakpoint-up (lg) {
            margin: 0 rem-calc(60);
        }
        @include media-breakpoint-down (xs) {
            padding-left: rem-calc(13);
        }
    }
    .slick-slider .slick-slide {
        padding: 0 rem-calc(6.5);
        @include media-breakpoint-up (sm) {
            padding: 0 rem-calc(13);
        }
        @include media-breakpoint-up (lg) {
            padding: 0 rem-calc(18);
        }
    }
    .cc-tile-image {
        img {
            width: 100%;
            height: auto;
        }
        .image-2 {
            display: none;
        }
    }
    .product-tile {
        position: relative;
        .cc-product-tile-label-discount {
            color: $red1;
            font-size: .875rem;
            font-weight: 600;
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            text-transform: uppercase;
            text-decoration: underline;
            font-style: italic;
        }
    }
    .tile-body {
        font-size: rem-calc(14);
        line-height: rem-calc(17);
        font-weight: $primary-font-medium;
        text-align: center;
        margin-top: rem-calc(20);
        @include media-breakpoint-up (sm) {
            margin-top: rem-calc(15);
        }
        .cc-pdp-link {
            margin-bottom: rem-calc(4);
        }
        .sales {
            .cc-price {
                font-weight: $primary-font-semibold;
                font-style: italic;
            }
        }
        .cc-price {
            font-weight: $primary-font-medium;
            &.cc-price-reduced {
                position: relative;
                background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 99 L99 0 L100 1 L1 100' fill='currentColor'/></svg>");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100% 100%, auto;
            }
        }

        @include original-and-pp-price-container(12, 12, 18, 6, center);
    }
    .container {
        padding: 0;
    }
    .cc-recommendations-arrows {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        width: rem-calc(26);
        height: rem-calc(26);
        &:first-child {
            left: 0;
        }
        &:last-child {
            right: 0;
        }
        &.arrows {
            &-left {
                background: url('../images/icon-caret-left.svg') left top no-repeat;
            }
            &-right {
                background: url('../images/icon-caret-right.svg') left top no-repeat;
            }
        }
        &:hover {
            opacity: .6;
        }
    }

    .multiple-items:not(.slick-initialized) {
        display: flex;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        max-width: rem-calc(1290);
        margin: 0 auto;

        @include media-breakpoint-down(xs) {
            padding: 0 rem-calc(6);
        }

        > * {
            padding: 0 rem-calc(18);
            width: 25%;
            flex: 0 0 auto;

            @include media-breakpoint-down(xs) {
                padding: 0 rem-calc(6);
                width: 43vw;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &.cc-brandCarousel {
        .slick-list {
            padding: 0 rem-calc(28);
        }

        .content-asset:not(.slick-initialized) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            max-width: rem-calc(1198);
            margin: 0 auto;

            @include media-breakpoint-down(xs) {
                flex-wrap: nowrap;
            }

            > .cc-slide {
                width: 16.6667%;

                @include media-breakpoint-down(xs) {
                    padding: 0 rem-calc(18);
                    width: auto;
                    flex: 0 0 auto;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &.cc-popularCategoriesCarousel {
        .cc-tile-image {
            img {
                border-radius: 50%;

                @include media-breakpoint-up (sm) {
                    width: rem-calc(150);
                }
            }
        }

        .content-asset:not(.slick-initialized) {
            display: flex;
            overflow: auto;
            -webkit-overflow-scrolling: touch;

            @include media-breakpoint-down(xs) {
                padding: 0 rem-calc(5);

                .tile-body {
                    display: flex;
                    justify-content: center;
                }
            }

            > .cc-slide {
                padding: 0 rem-calc(10);

                @include media-breakpoint-down(xs) {
                    width: 28.5vw;
                    flex: 0 0 auto;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &.cc-trendingProductsCarousel {
        .slick-list {
            @include media-breakpoint-up (sm) {
                padding:0 11% 0 0;
            }
        }

        .content-asset:not(.slick-initialized) {
            display: flex;
            overflow: auto;
            -webkit-overflow-scrolling: touch;

            @include media-breakpoint-down(xs) {
                padding: 0 rem-calc(6);
            }

            > .cc-slide {
                padding: 0 rem-calc(10);
                width: 22.2%;
                flex: 0 0 auto;

                @include media-breakpoint-down(xs) {
                    width: 66vw;
                    padding: 0 rem-calc(6);
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &.cc-popularCategoriesCarousel,
    &.cc-trendingProductsCarousel,
    &.cc-shopByBlockCarousel {
        border: 0;
        padding: 0 0 rem-calc(20) 0;
        margin-top: rem-calc(30);

        @include media-breakpoint-down (xs) {
            padding-bottom: rem-calc(15);
            margin-top: rem-calc(15);
        }

        .slick-slider {
            .slick-slide {
                padding: 0 rem-calc(10);

                @include media-breakpoint-down (xs) {
                    font-size: rem-calc(7);
                }
            }
        }

        .slick-list {
            margin: 0 0 0 rem-calc(-10);
        }

        .slick-track {
            margin: 0;
        }

        .cc-recommendations-arrows {
            top: rem-calc(-45);
            transform: none;
            right: 0;
            left: auto;

            &.arrows-left {
                right: rem-calc(47);
            }

            &.arrows-right {
                top: rem-calc(-43);
                right: rem-calc(-13);
            }
        }

        /* Editor mode - start */
        .experience-Popular_Categories_Block & {
            .slick-slide,
            .slick-track {
                float: none;
                width: 100% !important;
            }

            .cc-slide {
                float: left;
                padding-right: rem-calc(20);
            }

            .experience-Popular_Categories_Block_Tab {
                overflow: hidden !important;
                width: rem-calc(4000) !important;
            }
        }

        .experience-Trending_Products_Block & {
            .slick-slide,
            .slick-track {
                float: none;
                width: 100% !important;
            }

            .cc-slide {
                float: left;
                padding-right: rem-calc(20);
                width: 20%;
                display: none;

                &:nth-child(-n+5) {
                    display: block;
                }
            }

            .experience-Trending_Products_Block_Tab,{
                overflow: hidden !important;
                width: 125% !important;
            }
        }

        .experience-Shop_By_Block & {
            .content-asset:not(.slick-initialized) {
                display: block;
            }

            .slick-slide,
            .slick-track {
                float: none;
                width: 100% !important;
            }

            .cc-slide {
                float: left;
                padding-right: rem-calc(20);
                width: 14.2%;
                display: none;

                &:nth-child(-n+14) {
                    display: block;
                }
            }

            .experience-Shop_By_Block_Tab {
                overflow: hidden !important;
                width: 101% !important;
            }
        }
        /* Editor mode - end */
    }

    &.tileSizeLarge2rows {
        .experience-Shop_By_Block & {
            .slick-slide,
            .slick-track {
                float: none;
                width: 100% !important;
            }

            .cc-slide {
                float: left;
                padding-right: rem-calc(20);
                width: 20%;
                display: none !important;

                &:nth-child(-n+10) {
                    display: block !important;
                }
            }

            .experience-Shop_By_Block_Tab {
                overflow: hidden !important;
                width: 101% !important;
            }
        }
    }

    &.tileSizeLarge {
        .experience-Shop_By_Block & {
            .slick-slide,
            .slick-track {
                float: none;
                width: 100% !important;
            }

            .cc-slide {
                float: left;
                padding-right: rem-calc(20);
                width: 20%;
                display: none !important;

                &:nth-child(-n+5) {
                    display: block !important;
                }
            }

            .experience-Shop_By_Block_Tab {
                overflow: hidden !important;
                width: 101% !important;
            }
        }
    }

    &.cc-shopByBlockCarousel {
        .slick-list {
            margin: 0 rem-calc(-15) 0 rem-calc(-5);

            @include media-breakpoint-up (sm) {
                margin: 0 rem-calc(-10);
            }
        }

        .product-tile {
            overflow: hidden;

            &:hover {
                .cc-image-container {
                    transform: scale(1.15);
                }
            }

            > a {
                display: block;
            }
        }

        .cc-image-container {
            transition: transform 0.2s ease-in-out;
        }

        .tile-body {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: rem-calc(10);
            right: rem-calc(10);
            margin: 0;
            color: $white;

            .cc-title {
                font-size: rem-calc(20);
                margin-bottom: rem-calc(5);

                @include media-breakpoint-down (xs) {
                    font-size: rem-calc(13);
                    margin-bottom: rem-calc(2);
                }
            }

            .cc-subtitle {
                font-size: rem-calc(26);
                line-height: 1.2;

                @include media-breakpoint-down (xs) {
                    font-size: rem-calc(15);
                }
            }
        }

        .slick-slider {
            .slick-slide {
                @include media-breakpoint-down (xs) {
                    padding: 0 rem-calc(5);
                }
            }
        }

        .content-asset:not(.slick-initialized) {
            display: flex;
            overflow: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;

            @include media-breakpoint-down(xs) {
                padding: 0 rem-calc(6);
            }

            > .cc-slide {
                padding: 0 rem-calc(10);
                width: 20%;
                flex: 0 0 auto;

                @include media-breakpoint-down(xs) {
                    width: 43vw;
                    padding: 0 rem-calc(6);
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &.tileSizeLarge2rows,
    &.tileSizeSmall {
        .slick-slider {
            .slick-slide {
                padding: 0;
                margin-bottom: rem-calc(-20);

                @include media-breakpoint-down (xs) {
                    margin-bottom: rem-calc(-10);
                }
            }
        }

        .slick-list {
            @include media-breakpoint-down (xs) {
                padding-right: 20%;
            }
        }

        .cc-slide {
            padding: rem-calc(0) rem-calc(10) rem-calc(20) rem-calc(10);

            @include media-breakpoint-down (xs) {
                padding: rem-calc(0) rem-calc(5) rem-calc(10) rem-calc(5);
            }
        }

        .content-asset:not(.slick-initialized) {
            display:grid;
            grid-auto-flow:column dense;
            grid-template-rows: rem-calc(266) rem-calc(266);
            grid-auto-columns: rem-calc(266);
            grid-gap: rem-calc(20);

            @include media-breakpoint-down(xs) {
                grid-template-rows: 43vw 43vw;
                grid-auto-columns: 43vw;
                grid-gap: rem-calc(12);
                padding: 0 rem-calc(12);
            }

            > .cc-slide {
                width: auto;
                padding: 0;

                &:nth-child(4n + 2):not(:last-child) {
                    grid-row:1;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &.tileSizeSmall {
        .content-asset:not(.slick-initialized) {
            @include media-breakpoint-up(sm) {
                grid-template-rows: rem-calc(185) rem-calc(185);
                grid-auto-columns: rem-calc(185);
            }
        }
    }

    &.cc-productsFromCategory {
        border: 0;
        padding: 0 0 rem-calc(20) 0;
        margin-top: rem-calc(30);

        @include media-breakpoint-down (xs) {
            padding-bottom: rem-calc(15);
            margin-top: rem-calc(15);
        }

        .slick-slider {
            overflow: hidden;

            .slick-list {
                margin: 0 rem-calc(-18);

                @include media-breakpoint-down (xs) {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 rem-calc(-6.5);
                    padding: 0 rem-calc(13);
                }
            }

            .slick-slide {
                width: 20%;

                @include media-breakpoint-down (xs) {
                    width: 50%;
                    padding-bottom: rem-calc(20);

                    &:nth-child(3) {
                        display: none;
                    }

                    &:nth-last-of-type(-n+2) {
                        padding-bottom: rem-calc(10);
                    }
                }
            }
        }

        .product-tile-show-more {
            aspect-ratio: 1/1;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f0f0f0;
            text-transform: uppercase;
            font-size: rem-calc(14);
            font-weight: $primary-font-semibold;
            text-decoration: underline;
        }

        @include media-breakpoint-down (xs) {
            padding: 0;
        }
    }

    .cc-product-label {
        .cc-container-dis-picture {
            img {
                max-height: rem-calc(50);
                width: auto;
            }
        }
    }
}

/* Editor mode - start */
.experience-commerce_layouts-popularCategoriesBlockTab,
.experience-commerce_layouts-trendingProductsBlockTab,
.experience-commerce_layouts-shopByBlockTab {
    .tab-pane {
        display: none;

        &.active {
            display: block;
        }
    }
}
/* Editor mode - end */

.cc-recommendations {
    .carousel-title {
        text-align: center;
    }

    &.cc-shopByBlockCarousel  {
        .product-tile {
            &:hover {
                .cc-image-container {
                    transform: none;
                }
            }
        }
    }
}
